@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url(./fonts/Roboto-Regular.ttf) format("truetype");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

body {
    font-family: "Roboto";
}

.empty-content {
    content: "";
}

/* The switch - the box around the slider */
.switch {
    @apply relative inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}

.slider:before {
    content: "";
    @apply h-6 w-6 left-1 bottom-1 bg-white;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    @apply translate-x-6;
}

/* Rounded sliders */
.slider.round {
    @apply rounded-3xl;
    /*border-radius: 34px;*/
}

.slider.round:before {
    border-radius: 50%;
}
